<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" sm="12">
        <BackButton :handler="gotoMail" />
      </v-col>
    </v-row>

    <div style="max-width: 80%; margin: auto">
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="titles blue-text">Message</div>
        <v-card class="pb-4 rounded-5" elevation="0">
          <v-card-text class="pb-0">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label for="">
                  {{ type == "sms*" ? "Title*" : "Subject*" }}
                </label>
                <v-text-field
                  v-model="messageForm.title"
                  outlined
                  required
                  :rules="[
                    (v) =>
                      !!v ||
                      (type == 'sms' ? 'Title' : 'Subject') + ' is required',
                  ]"
                  hide-details="auto"
                  dense
                  validate-on-blur
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" v-if="type === 'email'">
                <div>
                  <span class="overline">Header Image</span>
                  <br />
                  <div style="width: auto; height: 240px">
                    <image-uploader
                      @upload="
                        (data) => {
                          messageForm.header_image = data;
                        }
                      "
                      @remove="
                        () => {
                          messageForm.header_image = null;
                        }
                      "
                      :image_path="
                        typeof messageForm.header_image === 'string'
                          ? messageForm.header_image
                          : null
                      "
                      :height="240"
                    />
                  </div>
                </div>
                <br />
              </v-col>
              <div class="ml-4" style="margin-top: -10px">
                <span class="overline">Placeholders</span>
                <v-tooltip top max-width="250">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon color="grey lighten-1">mdi-information</v-icon>
                    </v-btn>
                  </template>
                  <span class="text-center">
                    Placeholder is used to add dynamic data in you message.
                    Placeholder will be replaced with actual data representing
                    each of them. Please click to copy and paste it on your
                    message
                  </span>
                </v-tooltip>
                <template v-for="item in placeholders">
                  <v-chip
                    :key="item.id"
                    class="mr-2 pa-4"
                    @click="copyPlaceholder(item)"
                  >
                    {{ item.name }}
                    <v-icon right small>mdi-content-copy</v-icon>
                  </v-chip>
                </template>
              </div>
              <v-col cols="12" sm="12" md="12">
                <label for="">Message</label>
                <v-textarea
                  v-if="type == 'sms'"
                  v-model="messageForm.message"
                  outlined
                  required
                  :rules="[(v) => !!v || 'Message is required']"
                  rows="3"
                  dense
                  validate-on-blur
                ></v-textarea>
                <text-editor
                  v-else
                  @complete="setEmailContent"
                  :message="emailEditContent"
                />
              </v-col>
            </v-row>
            <template v-if="type === 'email'">
              <span class="overline">Footer Image</span>
              <v-row>
                <v-col cols="4" v-for="(num, index) in 3" :key="index">
                  <div style="width: auto; height: 240px">
                    <image-uploader
                      @upload="
                        (data) => {
                          messageForm.footer_images[index] = data;
                        }
                      "
                      @remove="
                        () => {
                          messageForm.footer_images[index] = null;
                        }
                      "
                      :image_path="messageForm.footer_images[index]"
                      :height="240"
                      message-text=""
                    />
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>

        <div class="titles blue-text mt-8">Configuration</div>
        <v-card elevation="0" class="rounded-5">
          <v-card-text class="pb-4">
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <label for="">Schedule Type*</label>
                <v-select
                  v-model="messageForm.message_type"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="name"
                  readonly
                  item-value="value"
                  :items="[
                    { name: 'Scheduled', value: 's' },
                    { name: 'Automated', value: 'a' },
                  ]"
                  required
                  :rules="[(v) => !!v || 'Type is required']"
                  dense
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  validate-on-blur
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <label for="">Type*</label>
                <v-select
                  v-model="messageForm.marketing_type"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="name"
                  item-value="value"
                  :items="[
                    { name: 'Marketing', value: 'm' },
                    { name: 'Announcement', value: 'a' },
                  ]"
                  @change="search"
                  required
                  :rules="[(v) => !!v || 'Type is required']"
                  dense
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  validate-on-blur
                ></v-select>
              </v-col>
              <v-col
                md="3"
                v-if="
                  messageForm.message_type == 's' ||
                  messageForm.message_type == null
                "
              >
                <label>Schedule date*</label>
                <date-field
                  v-model="messageForm.date"
                  :rules="[(v) => !!v || 'Schedule date is required']"
                  :backFill="
                    checkBackfillPermission($modules.marketing[type].slug)
                  "
                  class-name="q-text-field shadow-0"
                  :outlined="true"
                  :hide-details="true"
                  dense
                  label=""
                ></date-field>
              </v-col>
              <v-col
                md="3"
                v-if="
                  messageForm.message_type == 's' ||
                  messageForm.message_type == null
                "
              >
                <label for="">Hour*</label>
                <v-text-field
                  v-model="messageForm.time"
                  type="time"
                  :min="minTime"
                  outlined
                  dense
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  validate-on-blur
                  required
                  :rules="[(v) => !!v || 'Schedule time is required']"
                ></v-text-field>
              </v-col>
              <v-col md="5" v-else>
                <label for="">Automation Rules</label>
                <v-select
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="name"
                  item-value="id"
                  v-model="messageForm.rule_id"
                  :items="rules"
                  dense
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  validate-on-blur
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div class="titles mt-8 blue-text">Audience</div>
        <v-card class="pb-4 rounded-5" elevation="1">
          <v-card-text class="pb-0">
            <v-row>
              <v-col md="6">
                <label for="">Product Types</label>
                <v-select
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="name"
                  item-value="id"
                  multiple
                  v-model="messageForm.product_type_ids"
                  :items="productTypes"
                  @change="search"
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
                >
                  <template
                    v-if="
                      messageForm.product_type_ids.length == productTypes.length
                    "
                    v-slot:selection="{ index }"
                  >
                    <span v-if="index == 0">All Product Types</span>
                  </template>
                  <template v-else v-slot:selection="{ item, index }">
                    <span v-if="index == 0">{{ item.name }}</span>
                    <span v-if="index == 1">, {{ item.name }}</span>
                    <span v-if="index == 2">, ...</span>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="toggle('productTypes', 'product_type_ids')"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="
                            messageForm.product_type_ids.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                          >{{ getIcon("productTypes", "product_type_ids") }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                </v-select>
              </v-col>
              <v-col md="6">
                <label for="">Service </label>
                <v-select
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="venueServices"
                  item-text="name"
                  item-value="venue_service_id"
                  multiple
                  v-model="messageForm.venue_service_ids"
                  :rules="[(v) => !!v || 'Services is required']"
                  @change="search"
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
                >
                  <template
                    v-if="
                      messageForm.venue_service_ids.length ==
                      venueServices.length
                    "
                    v-slot:selection="{ index }"
                  >
                    <span v-if="index == 0">All Services</span>
                  </template>
                  <template v-else v-slot:selection="{ item, index }">
                    <span v-if="index == 0">{{ item.name }}</span>
                    <span v-if="index == 1">, {{ item.name }}</span>
                    <span v-if="index == 2">, ...</span>
                  </template>

                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="toggle('venueServices', 'venue_service_ids')"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="
                            messageForm.venue_service_ids.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                          >{{ getIcon("venueServices", "venue_service_ids") }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col md="6">
                <label for="">Membership Type</label>
                <v-select
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  v-model="messageForm.membership_type"
                  item-text="name"
                  item-value="value"
                  :items="[
                    { name: 'All', value: null },
                    { name: 'Non Members', value: 'N' },
                    { name: 'Members Only', value: 'M' },
                  ]"
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
                  @change="search"
                ></v-select>
              </v-col>
              <v-col md="6" v-if="messageForm.membership_type != null">
                <label for="">Memberships</label>
                <v-select
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="memberships"
                  item-text="name"
                  item-value="membership_id"
                  :hint="
                    messageForm.membership_type == 'N'
                      ? 'Exclude customer with memberships'
                      : 'Include customers with memberships'
                  "
                  persistent-hint
                  multiple
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
                  v-model="messageForm.membership_ids"
                  @change="search"
                  :rules="[(v) => !!v || 'Membership is required']"
                >
                  <template
                    v-if="
                      messageForm.membership_ids.length == memberships.length &&
                      memberships.length != 0
                    "
                    v-slot:selection="{ index }"
                  >
                    <span v-if="index == 0">All Memberships</span>
                  </template>
                  <template v-else v-slot:selection="{ item, index }">
                    <span v-if="index == 0">{{ item.name }}</span>
                    <span v-if="index == 1">, {{ item.name }}</span>
                    <span v-if="index == 2">, ...</span>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="toggle('memberships', 'membership_ids')"
                      v-if="memberships.length != 0"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="
                            messageForm.membership_ids.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                          >{{ getIcon("memberships", "membership_ids") }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <div class="md-card md-theme-default ma-3 rounded-5 shadow-0">
                <div class="md-card-content">
                  <v-row>
                    <v-col md="12" sm="12" lg="12">
                      <div class="table-responsive">
                        <table class="table border-collapse text-center">
                          <thead class="">
                            <tr
                              class="opacity-70 tr-neon tr-rounded text-center"
                            >
                              <th class="">
                                <div class="md-table-head-label"></div>
                              </th>
                              <th class="">
                                <div class="">Name</div>
                                <div class="search_column">
                                  <v-autocomplete
                                    solo
                                    filled
                                    label="Name"
                                    v-model="messageForm.name"
                                    item-value="name"
                                    item-text="name"
                                    :items="names"
                                    :loading="isLoading"
                                    :search-input.sync="name"
                                    @change="getFilterData"
                                  ></v-autocomplete>
                                </div>
                              </th>
                              <th>
                                <div class="">Mobile</div>
                                <div class="search_column">
                                  <v-autocomplete
                                    solo
                                    filled
                                    label="Mobile"
                                    v-model="messageForm.mobile"
                                    item-value="mobile"
                                    item-text="mobile"
                                    :items="phones"
                                    :loading="isLoading"
                                    :search-input.sync="phone"
                                    @change="getFilterData"
                                  ></v-autocomplete>
                                </div>
                              </th>
                              <th>
                                <div class="">Email</div>
                                <div class="search_column">
                                  <v-autocomplete
                                    solo
                                    filled
                                    label="Email"
                                    v-model="messageForm.email"
                                    item-value="email"
                                    item-text="email"
                                    :items="emails"
                                    :loading="isLoading"
                                    :search-input.sync="email"
                                    @change="getFilterData"
                                  ></v-autocomplete>
                                </div>
                              </th>
                              <th style="position: relative">
                                <div class="">Age</div>
                                <div class="search_column">
                                  <v-select
                                    v-model="messageForm.age"
                                    :items="AgeRange"
                                    @change="ageChange"
                                    label="Age"
                                    ref="ageRef"
                                    solo
                                    filled
                                  >
                                    <template v-slot:append-item>
                                      <v-divider class="mb-2"></v-divider>
                                      <div style="width: 150px">
                                        <v-list-item>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              <v-text-field
                                                label="Start Age"
                                                class="shrink"
                                                v-model="fromAge"
                                                append-icon="mdi-keyboard_arrow_down"
                                              ></v-text-field>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                              <v-text-field
                                                label="End Age"
                                                class="shrink"
                                                v-model="toAge"
                                                append-icon="mdi-keyboard_arrow_down"
                                              ></v-text-field>
                                            </v-list-item-subtitle>
                                            <v-btn
                                              small
                                              color="primary"
                                              @click="ageOkClick"
                                              >Ok</v-btn
                                            >
                                          </v-list-item-content>
                                        </v-list-item>
                                      </div>
                                    </template>
                                  </v-select>
                                </div>
                              </th>
                              <th>
                                <div>Gender</div>
                                <div class="search_column">
                                  <v-autocomplete
                                    v-model="messageForm.gender"
                                    solo
                                    filled
                                    label="Gender"
                                    :items="['All', 'Male', 'Female']"
                                    @change="search"
                                  ></v-autocomplete>
                                </div>
                              </th>
                              <th>
                                <div class="">Country</div>
                                <div class="search_column">
                                  <v-autocomplete
                                    solo
                                    filled
                                    label="Country"
                                    v-model="messageForm.country_ids"
                                    item-value="id"
                                    item-text="name"
                                    multiple
                                    :items="countries"
                                    @change="getFilterData"
                                  >
                                    <template
                                      v-if="
                                        messageForm.country_ids.length ==
                                        countries.length
                                      "
                                      v-slot:selection="{ index }"
                                    >
                                      <span v-if="index == 0"
                                        >All Countries</span
                                      >
                                    </template>
                                    <template
                                      v-else
                                      v-slot:selection="{ item, index }"
                                    >
                                      <span v-if="index == 0">
                                        {{ item.name }}
                                      </span>
                                      <span v-if="index == 1">, ...</span>
                                    </template>

                                    <template v-slot:prepend-item>
                                      <v-list-item
                                        ripple
                                        @click="
                                          toggle('countries', 'country_ids')
                                        "
                                      >
                                        <v-list-item-action>
                                          <v-icon
                                            :color="
                                              messageForm.country_ids.length > 0
                                                ? 'indigo darken-4'
                                                : ''
                                            "
                                            >{{
                                              getIcon(
                                                "countries",
                                                "country_ids"
                                              )
                                            }}</v-icon
                                          >
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            >All</v-list-item-title
                                          >
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                  </v-autocomplete>
                                </div>
                              </th>
                              <th>
                                <div class="">Customer Type</div>
                                <div class="search_column">
                                  <v-autocomplete
                                    solo
                                    filled
                                    label="Customer Type"
                                    v-model="messageForm.customer_type_ids"
                                    item-value="id"
                                    item-text="name"
                                    multiple
                                    :items="customerTypes"
                                    @change="getFilterData"
                                  >
                                    <template
                                      v-if="
                                        messageForm.customer_type_ids.length ==
                                        customerTypes.length
                                      "
                                      v-slot:selection="{ index }"
                                    >
                                      <span v-if="index == 0"
                                        >All Customer Types</span
                                      >
                                    </template>
                                    <template
                                      v-else
                                      v-slot:selection="{ item, index }"
                                    >
                                      <span v-if="index == 0">
                                        {{ item.name }}
                                      </span>
                                      <span v-if="index == 1">, ...</span>
                                    </template>

                                    <template v-slot:prepend-item>
                                      <v-list-item
                                        ripple
                                        @click="
                                          toggle(
                                            'customerTypes',
                                            'customer_type_ids'
                                          )
                                        "
                                      >
                                        <v-list-item-action>
                                          <v-icon
                                            :color="
                                              messageForm.customer_type_ids
                                                .length > 0
                                                ? 'indigo darken-4'
                                                : ''
                                            "
                                            >{{
                                              getIcon(
                                                "customerTypes",
                                                "customer_type_ids"
                                              )
                                            }}</v-icon
                                          >
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            >All</v-list-item-title
                                          >
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                  </v-autocomplete>
                                </div>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              class="md-table-row"
                              v-for="data in searchDatas"
                              :key="data.id"
                            >
                              <td class="md-table-cell">
                                <div class="md-table-cell-container">
                                  <v-checkbox
                                    v-model="selected"
                                    color="#66c8c8"
                                    :value="data.id"
                                    @change="customerSelection($event)"
                                    hide-details
                                  ></v-checkbox>
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div class="md-table-cell-container">
                                  <v-btn
                                    small
                                    color="#00b0af"
                                    class="d-inline-block text-truncate"
                                    style="
                                      max-width: 130px;
                                      min-width: 130px;
                                      text-transform: none;
                                    "
                                    dark
                                    @click="showUserModel(data.id)"
                                    >{{ data.name }}</v-btn
                                  >
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div class="md-table-cell-container">
                                  {{ data.mobile || "NA" }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div class="md-table-cell-container">
                                  {{ data.email || "NA" }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div class="md-table-cell-container">
                                  {{ data.age || "NA" }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div class="md-table-cell-container">
                                  {{ data.gender || "NA" }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div class="md-table-cell-container">
                                  {{ data.country || "NA" }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container"
                                  v-if="data.customer_types"
                                >
                                  <v-chip
                                    v-for="(
                                      data, index
                                    ) in data.customer_types.map((x) => x.name)"
                                    :key="index"
                                    class="ma-2"
                                    label
                                    small
                                    style="margin: 5px !important"
                                  >
                                    {{ data }}
                                  </v-chip>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-col>
                  </v-row>
                </div>

                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-6">
                    <v-pagination
                      class="new-pagination"
                      total-visible="7"
                      v-model="page"
                      :length="totalPages"
                    ></v-pagination>
                  </div>

                  <v-col class="d-flex align-center justify-end" cols="3">
                    <div
                      class="d-flex align-center justify-end text-sm gap-x-2"
                    >
                      <span class=""> Result </span>
                      <div style="width: 80px">
                        <v-select
                          v-model="perPage"
                          :items="[10, 15, 25, 50]"
                          :menu-props="{ bottom: true, offsetY: true }"
                          class="q-autocomplete text-sm"
                          flat
                          hide-details
                          solo
                          @change="search"
                        ></v-select>
                      </div>
                      <span>Per Page</span>
                    </div>
                  </v-col>
                </div>
              </div>
              <CustomerModel
                v-bind="userModel"
                @close="userModel.userID = null"
                @updated="search()"
              />
            </v-row>
          </v-card-text>
        </v-card>
        <v-row class="mt-3">
          <v-spacer></v-spacer>
          <v-btn @click="gotoMail" class="ma-2 white--text blue-color" text
            >Close
          </v-btn>
          <v-btn
            color="darken-1"
            class="ma-2 white--text teal-color"
            text
            @click="saveMarketingMessage"
            >Save
          </v-btn>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import TextEditor from "@/components/Marketing/TextEditor";
import CustomerModel from "../Clients/Customer/CustomerModel";
import { mapGetters } from "vuex";
import BackButton from "@/components/Common/BackButton.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";

export default {
  data() {
    return {
      messageForm: {
        message_type: "s",
        venue_service_ids: [],
        country_ids: [],
        membership_ids: [],
        product_type_ids: [],
        customer_type_ids: [],
        footer_images: [],
        header_image: null,
      },
      menu: false,
      currentPage: 0,
      pageSize: 10,
      items: [],
      names: [],
      name: null,
      customer_type_id: null,
      country: null,
      customerTypes: [],
      phones: [],
      phone: null,
      emails: [],
      email: null,
      isLoading: false,
      searchDatas: [],
      searchFlag: false,
      AgeRange: ["All", "18-25", "26-30", "31-35"],
      Age: [],
      toAge: null,
      fromAge: null,
      page: 1,
      perPage: null,
      totalPages: 1,
      userModel: { userID: null, type: "details" },
      selected: [],
      currentPageIds: [],
      valid: false,
      rules: [],
      placeholders: [],
      type: "sms",
      emailEditContent: "",
      currentDay: moment().format("YYYY-MM-DD"),
    };
  },
  components: {
    ImageUploader,
    BackButton,
    CustomerModel,
    TextEditor,
  },
  mounted() {
    if (typeof this.$route.params.type != "undefined") {
      this.type = this.$route.params.type;
      this.messageForm.type = this.type;
    }
    if (typeof this.$route.params.id != "undefined") {
      this.messageForm.id = this.$route.params.id;
      this.getMessageDetails();
    }
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getMemberships.status == false) {
      this.$store.dispatch("loadMemberships");
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    this.getAutomatedRules();
    this.getPlaceholders();
    this.getCustomerType();
  },
  watch: {
    page() {
      this.search();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
  },
  computed: {
    ...mapGetters({
      checkReadPermission: "checkReadPermission",
    }),
    minTime() {
      let marker = "00:00";
      if (
        this.messageForm.date == null ||
        moment(this.messageForm.date, "YYYY-MM-DD").isSame(moment(), "day")
      ) {
        marker = moment().format("HH:mm");
      }
      return marker;
    },
    getFormatDate() {
      if (this.messageForm.date) {
        return moment(String(this.messageForm.date)).format("Do MMM YYYY");
      }
      return null;
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    memberships() {
      return this.$store.getters.getMemberships.data;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
  },
  methods: {
    toggle(type, field) {
      this.$nextTick(() => {
        if (this.messageForm[field].length == this[type].length) {
          this.messageForm[field] = [];
        } else {
          if (type == "memberships") {
            this.messageForm[field] = this[type].map((x) => x.membership_id);
          } else {
            this.messageForm[field] = this[type].map((x) => x.id);
          }
        }
        this.search();
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (this.messageForm[field].length == this[type].length) {
        icon = "mdi-close-box";
      }
      if (
        this.messageForm[field].length > 0 &&
        this.messageForm[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },

    copyPlaceholder(data) {
      const el = document.createElement("textarea");
      el.value = data.placeholder;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showInfo(
        "Placeholder for " + data.name + " copied to clipboard",
        2000
      );
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    filterSearch(type, val) {
      if (val == "All") return;
      this.isLoading = true;
      this.$http
        .post("venues/marketing/customers?" + type + "=" + val)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift(val);
              this.names.unshift("All");
            } else if (type == "mobile") {
              this.phones = response.data.data;
              this.phones.unshift(val);
              this.phones.unshift("All");
            } else if (type == "email") {
              this.emails = response.data.data;
              this.emails.unshift(val);
              this.emails.unshift("All");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterData() {
      this.page = 1;
      this.search();
    },
    ageOkClick() {
      if (this.fromAge != null && this.toAge != null) {
        this.messageForm.from_age = this.fromAge;
        this.messageForm.to_age = this.toAge;
        let customAgeRange = this.fromAge + "-" + this.toAge;
        this.AgeRange.push(customAgeRange);
        this.messageForm.age = customAgeRange;
        this.toAge = null;
        this.fromAge = null;
        this.$refs.ageRef.blur();
        this.search();
      }
    },
    ageChange() {
      if (this.messageForm.age != "All") {
        let data = this.messageForm.age.split("-");
        this.messageForm.from_age = data[0];
        this.messageForm.to_age = data[1];
        this.search();
      } else {
        this.allAge();
      }
    },
    allAge() {
      delete this.messageForm.from_age;
      delete this.messageForm.to_age;
      delete this.messageForm.age;
      this.search();
    },
    search() {
      if (this.messageForm.name == "All") {
        this.messageForm.name = "";
      }
      if (this.messageForm.email == "All") {
        this.messageForm.email = "";
      }
      if (this.messageForm.mobile == "All") {
        this.messageForm.mobile = "";
      }
      if (this.messageForm.gender == "All") {
        this.messageForm.gender = "";
      }
      if (this.messageForm.country == "All") {
        this.messageForm.country = [];
      }

      if (this.messageForm.customer_type == "All") {
        this.messageForm.customer_type = [];
      }

      this.messageForm.page = this.page;
      this.messageForm.per_page = this.perPage;

      this.$http
        .post("venues/marketing/customers", this.messageForm)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.searchDatas = response.data.data;
            this.totalPages = response.data.total_pages;
            this.selected = response.data.data.map((data) => data.id);
            if (
              typeof this.messageForm.excluded_customer_ids != "undefined" &&
              this.messageForm.excluded_customer_ids.length > 0
            ) {
              this.selected = this.selected.filter(
                (i) => !this.messageForm.excluded_customer_ids.includes(i)
              );
            }
            this.currentPageIds = response.data.data.map((data) => data.id);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    getPlaceholders() {
      this.$http
        .get("venues/marketing/messages/placeholders?type=" + this.type)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.placeholders = data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getAutomatedRules() {
      let rule = "";
      if (this.messageForm.rule_id != null) {
        rule = "&rule_id=" + this.messageForm.rule_id;
      }
      this.$http
        .get("venues/marketing/messages/rules?type=" + this.type + rule)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.rules = data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    customerSelection() {
      let data = this.currentPageIds.filter((i) => !this.selected.includes(i));
      if (typeof this.messageForm.excluded_customer_ids == "undefined") {
        this.messageForm.excluded_customer_ids = [];
      }
      this.messageForm.excluded_customer_ids.push(...data);
      this.messageForm.excluded_customer_ids =
        this.messageForm.excluded_customer_ids.filter(
          (id) => !this.selected.includes(id)
        );
      this.messageForm.excluded_customer_ids = [
        ...new Set(this.messageForm.excluded_customer_ids),
      ];
    },
    saveMarketingMessage() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      if (this.messageForm.message_type == "s") {
        this.messageForm.schedule_date = moment(
          this.messageForm.date + " " + this.messageForm.time,
          "YYYY-MM-DD HH:mm"
        ).format("YYYY-MM-DD HH:mm:ss");
        delete this.messageForm.rule_id;
      } else {
        this.messageForm.schedule_date = moment().format("YYYY-MM-DD HH:mm:ss");
      }
      const formData = new FormData();

      // Loop through the keys of the input object
      for (let key in this.messageForm) {
        // Check if the property is an array
        if (Array.isArray(this.messageForm[key])) {
          if (this.messageForm[key].length > 0) {
            // Loop through the array elements and append each one
            this.messageForm[key].forEach(function (item, index) {
              formData.append(key + `[${index}]`, item);
            });
          }
        } else {
          // Otherwise, append the value directly
          formData.append(key, this.messageForm[key]);
        }
      }
      var config = {
        method: "post",
        url:
          "venues/marketing" +
          (this.messageForm.id != null ? "/" + this.messageForm.id : ""),
        data: formData,
      };
      this.$http(config)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.showSuccess("Added successfully");
            this.$router.push({
              name: "MailSMS",
              params: { type: this.type },
            });
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getMessageDetails() {
      this.showLoader("Loading");
      this.$http
        .get("venues/marketing/" + this.messageForm.id)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.messageForm.title = data.title;
            this.messageForm.message = data.message;
            if (this.type == "email") {
              this.emailEditContent = data.message;
            }
            this.messageForm.message_type = data.message_type;
            this.messageForm.marketing_type = data.marketing_type;
            this.messageForm.header_image = data.header_image;
            this.messageForm.footer_images = data.footer_images;
            this.$set(
              this.messageForm,
              "date",
              moment(data.schedule_date, "YYYY-MM-DD HH:mm:ss").format(
                "YYYY-MM-DD"
              )
            );
            this.$set(
              this.messageForm,
              "time",
              moment(data.schedule_date, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
            );

            if (data.name_filter != null) {
              this.names.unshift(data.name_filter);
              this.names.unshift("All");
              this.messageForm.name = data.name_filter;
            }
            if (data.mobile_filter != null) {
              this.phones.unshift(data.mobile_filter);
              this.phones.unshift("All");
              this.messageForm.mobile = data.mobile_filter;
            }
            if (data.email_filter != null) {
              this.emails.unshift(data.email_filter);
              this.emails.unshift("All");
              this.messageForm.email = data.email_filter;
            }

            this.messageForm.membership_type = data.membership_type;
            this.messageForm.venue_service_ids = data.venue_service_ids.map(
              (item) => parseInt(item.venue_service_id)
            );
            this.messageForm.membership_ids = data.membership_ids.map(
              (item) => item.membership_id
            );
            this.messageForm.country_ids = data.country_ids.map((item) =>
              parseInt(item.country_id)
            );
            if (data.customer_type_ids) {
              this.messageForm.customer_type_ids = data.customer_type_ids.map(
                (item) => parseInt(item.customer_type_ids)
              );
            }

            this.messageForm.product_type_ids = data.product_type_ids.map(
              (item) => parseInt(item.product_type_id)
            );

            if (data.to_age != null && data.from_age != null) {
              let age = data.from_age + "-" + data.to_age;
              if (!this.AgeRange.includes(age)) this.AgeRange.push(age);
              this.messageForm.age = age;
            }

            if (data.customer_type_id != "" || data.customer_type_id != null)
              this.messageForm.customer_type_id = data.customer_type_id;

            if (data.excluded_customer_ids != null) {
              this.messageForm.excluded_customer_ids =
                data.excluded_customer_ids;
            }
            this.messageForm.rule_id = data.rule_id;
            this.search();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getCustomerType() {
      this.$http
        .get("general-types/customer-type")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.customerTypes = data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    setEmailContent(content) {
      this.messageForm.message = content;
    },
    gotoMail() {
      this.$router.push({
        name: "MailSMS",
        params: { type: this.type },
      });
    },
  },
};
</script>

<style></style>
